.btn-sq-giant {
  width: 250px !important;
  height: 250px !important;
}

.btn-sq-lg {
  width: 150px !important;
  height: 150px !important;
}

.btn-sq {
  width: 100px !important;
  height: 100px !important;
  font-size: 10px;
}

.btn-sq-sm {
  width: 50px !important;
  height: 50px !important;
  font-size: 10px;
}

.btn-sq-xs {
  width: 25px !important;
  height: 25px !important;
  padding:2px;
}

#bi {
  margin-top: 6px;
}


.placeholder {
  color: #aaa;
}

.panel-success > .panel-heading .badge {
  color: #000;
  background-color: #fff;
}

.panel-title-buttons {
  margin-top: 6px;
}

.panel-title-md-buttons {
  margin-top: 8px;
}

.label {
  font-size: 100%;
}

.notify-item {
  display: block;
  padding: 3px 8px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;

}

.notify-item:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}

.notify-close {
  padding-right: 10px;
}

.notify-icon {
  font-size: 115%
}

.notify-badge {
   background: red;
   position: relative;
   -moz-transform: translate(-35%, -60%); /* For Firefox */
   -ms-transform: translate(-35%, -60%); /* for IE */
   -webkit-transform: translate(-35%, -60%); /* For Safari, Chrome, iOS */
   -o-transform: translate(-35%, -60%); /* For Opera */
 }

.notify-badge-2x {
  background: red;
  position: relative;
  -moz-transform: translate(-70%, -80%); /* For Firefox */
  -ms-transform: translate(-80%, -80%); /* for IE */
  -webkit-transform: translate(-70%, -80%); /* For Safari, Chrome, iOS */
  -o-transform: translate(-70%, -80%); /* For Opera */
}

.notifiy-badge {
  font-size: 70%;
  background: red;
  position: relative;
  top: -10px;
  left: -6px;
}

.action-btns {
  margin-top: -4px;
}

.action-btn {
  margin-top: 4px;
}

.no-wrap {
  white-space: nowrap;
}

body {
  padding-bottom: 40px;
  background-color: #e3e3e3;
}

.well-info {
  background-color: #d9edf7;
}

.well-warning {
  background-color: #f0ad4e;
}

.table-has-error, .table-has-error > tbody > tr > td, .table-has-error > thead > tr > th {
  border: 1px solid #a94442;
}

.flip {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: "FlipV";
  -ms-filter: "FlipV";
}

